.login-overlay .panel-body{
    min-height: 244px;
}
.has-feedback{
    position: relative;
}
.form-control-feedback{
    right: 10px;
    left: auto;
    top: 10px;
    position: absolute;
}
.login-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}
.card-login {
    background-color: #FFFFFF;
    opacity: 1;
    border: 0;
    border-radius: 0.125rem !important;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    padding: 2rem;

}
.bienvenida{
    color:white;
    font-weight: bold;
}
.buttons-box{
    display: flex;
    align-items: center;
    justify-content: center;
}

.body-img{
    background: url(../../../../../assets/img/back-img.jpg);
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
}
.body-img:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #FFFFFF;
    opacity: 0.45;
}
.blue-gradient-bg{
    height: 100vh;
    position: relative;
    /*background: #007bff;*/
}

