.dashboard-graph {
    height: 250px;
    position: relative;
    width: 100%;
}
.dashboard-list-graph {
    height: auto;
    max-height: 10rem;
}

@media (min-height: 1000px) {
    .dashboard-graph {
        height: 26vh !important;
    }
    .dashboard-list-graph {
        max-height: 7rem;
    }
}