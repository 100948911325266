.react-bootstrap-table table{table-layout:auto }
.react-bootstrap-table th.sortable{cursor:pointer}
.react-bootstrap-table th .order>.dropdown>.caret{margin:10px 0 10px 5px;color:#ccc}
.react-bootstrap-table th .order>.dropup>.caret{margin:10px 0;color:#ccc}
.react-bootstrap-table th>.react-bootstrap-table-sort-order>.caret{margin:10px 6.5px}
.react-bootstrap-table th .order-4:before{margin-left:3.5px;content:"\2191";opacity:.4}
.react-bootstrap-table th .order-4:after{content:"\2193";opacity:.4}
.react-bootstrap-table th .caret-4-asc:before{margin-left:3.5px;content:"\2191"}
.react-bootstrap-table th .caret-4-asc:after{content:"\2193";opacity:.4}
.react-bootstrap-table th .caret-4-desc:before{margin-left:3.5px;content:"\2191";opacity:.4}
.react-bootstrap-table th .caret-4-desc:after{content:"\2193"}
.react-bootstrap-table th[data-row-selection]{width:30px}
.react-bootstrap-table td>.selection-input-4,.react-bootstrap-table th>.selection-input-4{margin:-4px}
.react-bootstrap-table td.react-bs-table-no-data{text-align:justify-all}
react-bootstrap-table td.react-bootstrap-table-editing-cell .animated{animation-fill-mode:both}
.react-bootstrap-table td.react-bootstrap-table-editing-cell .animated.bounceIn,.react-bootstrap-table td.react-bootstrap-table-editing-cell .animated.bounceOut{animation-duration:.75s}
.react-bootstrap-table td.react-bootstrap-table-editing-cell .animated.shake{animation-duration:.3s}
@keyframes shake{from,to{transform:translate3d(0,0,0)}10%,50%,90%{transform:translate3d(-10px,0,0)}30%,70%{transform:translate3d(10px,0,0)}}.react-bootstrap-table td.react-bootstrap-table-editing-cell .shake{animation-name:shake}
@keyframes bounceIn{20%,40%,60%,80%,from,to{animation-timing-function:cubic-bezier(.215,.61,.355,1)}0%{opacity:0;transform:scale3d(.3,.3,.3)}20%{transform:scale3d(1.1,1.1,1.1)}40%{transform:scale3d(.9,.9,.9)}60%{opacity:1;transform:scale3d(1.03,1.03,1.03)}80%{transform:scale3d(.97,.97,.97)}to{opacity:1;transform:scale3d(1,1,1)}}
.react-bootstrap-table td.react-bootstrap-table-editing-cell .bounceIn{animation-name:bounceIn}
@keyframes bounceOut{20%{transform:scale3d(.9,.9,.9)}50%,55%{opacity:1;transform:scale3d(1.1,1.1,1.1)}to{opacity:0;transform:scale3d(.3,.3,.3)}}
.react-bootstrap-table td.react-bootstrap-table-editing-cell .bounceOut{animation-name:bounceOut}
.react-bootstrap-table .reset-expansion-style{padding:0}.react-bootstrap-table .row-expansion-style{padding:8px}
.react-bootstrap-table .row-expand-slide-appear{max-height:0;overflow:hidden}
.react-bootstrap-table .row-expand-slide-appear-active{max-height:1000px;transition:max-height 3s linear}
.react-bootstrap-table .row-expand-slide-exit{max-height:1000px}
.react-bootstrap-table .row-expand-slide-exit-active{max-height:0;overflow:hidden;transition:max-height .4s cubic-bezier(0,.95,0,.95)}

td {
    padding: 5px;
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
}
.rc-tabs-top .rc-tabs-bar {
    border-bottom: none !important;
}
.rc-tabs-top .rc-tabs-nav-wrap {
    width: 97%;
    margin-left: auto;
    margin-right: auto;
}
.rc-tabs-tab {
    background-color: #f2f2f2;
}
.rc-tabs-nav-wrap {
    background-color: transparent;
}
.rc-tabs-tab-active, .rc-tabs-tab-active {
    color: #FFF !important;
    font-weight: bolder;
    background-color: #AA1111;
    border-bottom: 0px solid;
}
.rc-tabs-ink-bar {
    background-color: #AA1111;
}

.rc-tabs-top .rc-tabs-tab {
    margin: 0;
    padding: 10px 50px 10px 50px;
    color: #000000;
}
.rc-tabs-tab:hover {
    color: green;
}
.rc-tabs-top {
    border-bottom: none;
}


.rc-tabs-bottom .rc-tabs-tab {
  padding-left: 30px;
  padding-right: 30px;
}

.rc-tabs {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.rc-tabs-content {
    flex: 1;
}
