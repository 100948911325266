.stats-section {    
    overflow: hidden;
    display: flex;
    flex-direction: row;
    border: 2px solid #e1e5eb;
    border-radius: 5px;
    flex-wrap: wrap;
    border-collapse: collapse;
    margin-bottom: 1rem;
}
.stats-section .stats-item {
    flex: auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    border: 1px solid #e1e5eb;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    width: 130px;
}

.stats-section .stats-item:last-child {
    border-right: 2px solid #e1e5eb;
}

@media (min-width: 768px) {
    .stats-section {
        flex-direction: row !important;
        margin-bottom: 0.5rem;
        flex-wrap: nowrap;
    }
    .stats-section .stats-item {
        width: 120px;
        border-style: solid;
        border-color: #e1e5eb;
        border-width: 0 2px 0 0;
    }
    .stats-section .stats-item:last-child {
        border-right: 1px solid transparent;
    }
}
@media (max-width: 768px) {
    #AGENT-TAB-CONTAINER {
        height: 800px !important;
        flex: none !important;
    }
}