.tooltip_card {
    position: absolute !important;
    overflow: hidden;
    min-width: 480px !important;
    max-width: 480px !important;
    border: 1px solid #cecece;
    background-color: #FFF;
    top: -260px !important;
    left: -186.5px !important;
    z-index: 1;
}
.tooltip_agent_card {
    position: absolute !important;
    overflow: hidden;
    min-width: 200px !important;
    border: 1px solid #cecece;
    background-color: #FFF;
    top: -85px !important;
    left: -86.5px !important;
    z-index: 1;
}

.card-color-indicator {
    position: absolute !important;
    overflow: hidden;
    min-width: 100px !important;
    background-color: rgba(255, 255, 255, 0.8) !important;
    top: 30px;
    right: 10px;
    /* opacity: 0.8; */
    border-radius: 5px !important;
}
.indicador-color {
    width: 20px;
    height: 15px;
    opacity: 1;
}
.tooltip_border_right {
    border-right: 0.5px solid #AFAFAF !important;
}
.tooltip_border_left {
    border-left: 0.5px solid #AFAFAF !important;
}
.tooltip_border_top {
    border-top: 0.5px solid #AFAFAF !important;
}
.tooltip_border_buttom {
    border-bottom: 0.5px solid #AFAFAF !important;
}

@media (max-width: 768px) {
    #AGENTS-JOB-MAP {
        height: 500px !important;
        flex: none !important;
    }
}
