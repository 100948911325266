@font-face {
  font-family: "Poppins SemiBold" !important;
  font-style: normal;
  src: url('Poppins-SemiBold.ttf');
  /*src: local("Poppins Semibol"), !* Safari, Android, iOS *!*/
  /*      url("Poppins-SemiBold.ttf") format("truetype"); !* Safari, Android, iOS *!*/
  /*font-weight: normal;*/
}

@font-face {
  font-family: "Poppins Regular" !important;
  font-style: normal;
  src: url('Poppins-Regular.ttf');
  /*src: local("Poppins Regular"), !* Safari, Android, iOS *!*/
        /*url("Poppins-Regular.ttf") format("truetype"); !* Safari, Android, iOS *!*/
  font-weight: normal;
}

@font-face {
  font-family: "Poppins Bold" !important;
  font-style: normal;
  src: url('Poppins-Bold.ttf');
  /*src: local("Poppins"), !* Safari, Android, iOS *!*/
  /*      url("Poppins-Bold.ttf") format("truetype"); !* Safari, Android, iOS *!*/
  font-weight: normal;
}

body {
    font-family: Poppins Regular, serif !important;
}
