@keyframes bgColor {
    0%{
        background: #CCCCCC;
    }
    20%{
        background: #7E8A97;
    }
    40%{
        background: #CCCCCC;
    }
    60%{
        background: #7E8A97;
    }
    80%{
        background: #CCCCCC;
    }
    100%{
        background: #7E8A97;
    }
}
